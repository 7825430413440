.hero {
  position: relative;
  background-color: var(--off-black);
  max-width: 1272px;
  margin: auto;
}

.hero__container {
  background-color: var(--off-black);
}
/* EARTHRISE HERO BG */
.hero__bg {
  display: flex;
  align-items: center;
  width: 100%;
  height: calc(100vh - 12rem);
  background: var(--off-black) url('../media/ei/earthrise-hero-bg.png')
    no-repeat 70% 2rem;
  background-size: cover;
}

/* WHITE NAV DOWN ARROW */
.hero__nav-arrow {
  margin-left: 0.5rem;
}

/* MISSION */
.hero__mission {
  background-color: var(--off-black);
  width: calc(100% - 3rem);
  margin: auto;
  position: relative;
  padding-top: 0.25rem;
}

@media (min-width: 768px) {
  .hero__mission {
    width: calc(100% - (32px * 2));
    max-width: 1368px;
  }
}

@media (min-width: 1024px) {
  .hero__mission {
    width: calc(100% - (80px * 2));
    max-width: 1368px;
  }
}

.mission__statement {
  will-change: transform, opacity;
  font-family: var(--heading-font);
  color: var(--pure-white);
  font-weight: 400;
  font-size: 1.25rem;
  line-height: 2rem;
  margin: 2rem 0;
  width: calc(100% - 2rem);
  opacity: 0;
}

@media (min-width: 460px) {
  .mission__statement {
    font-size: 1.5rem;
    line-height: 2.25rem;
    margin-top: 2.25rem;
  }
}

@media (min-width: 768px) {
  .mission__statement {
    font-size: 2.5rem;
    line-height: 4rem;
    margin-top: 3rem;
  }
}

@media (min-width: 1200px) {
  .mission__statement {
    font-size: 3.75rem;
    line-height: 5.25rem;
    margin-top: 3rem;
  }
}

.module {
  position: relative;
  padding-bottom: 180px;
}

@media (min-width: 1200px) {
  .module {
    padding-top: 120px;
  }
}

.two-column-text__grid {
  --grid-side: 1.5rem;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: 1.125rem;
}

@media (min-width: 768px) {
  .two-column-text__grid {
    --grid-side: 32px;
    max-width: 1368px;
    grid-template-columns: repeat(8, 1fr);
    grid-column-gap: 24px;
  }
}

@media (min-width: 1024px) {
  .two-column-text__grid {
    --grid-side: 32px;
    max-width: 1272px;
    grid-template-columns: repeat(12, 1fr);
    grid-column-gap: 24px;
  }
}

@media (min-width: 1200px) {
  .two-column-text__grid {
    --grid-side: 80px;
  }
}

.mission__title,
.mission__text {
  font-family: var(--body-font);
  color: var(--pure-white);
  grid-column-start: 1;
  grid-column-end: 5;
}

@media (min-width: 768px) {
  .mission__title,
  .mission__text {
    grid-column-start: 1;
    grid-column-end: 9;
  }
}

.mission__title {
  will-change: transform, opacity;
  display: block;
  font-family: var(--heading-font);
  font-size: 1.25rem;
  font-weight: 400;
  margin-top: 5rem;
  margin-block-end: 0;
  opacity: 0;
  transform: translateY(10rem);
}

@media (min-width: 768px) {
  .mission__title {
    font-size: 1.5rem;
    line-height: 2.25rem;
  }
}

@media (min-width: 1024px) {
  .mission__title {
    font-size: 2rem;
    line-height: 3rem;
  }
}

@media (min-width: 1200px) {
  .mission__title {
    grid-column-start: 1;
    grid-column-end: 6;
    font-size: 3.125rem;
    line-height: 4rem;
  }
}

.mission__text {
  will-change: transform, opacity;
  display: block;
  font-size: 14px;
  line-height: 1.5rem;
  margin-top: 1.5rem;
  opacity: 0;
  transform: translateY(10rem);
}

@media (min-width: 460px) {
  .mission__text {
    font-size: 1rem;
    line-height: 1.75rem;
  }
}

@media (min-width: 768px) {
  .mission__text {
    margin-top: 2rem;
  }
}

@media (min-width: 1200px) {
  .mission__text {
    grid-column-start: 6;
    grid-column-end: 12;
    font-size: 1.375rem;
    line-height: 2.5rem;
  }
}

.slide-in--up {
  animation-name: effects-system-up;
  animation-duration: 1s;
  animation-timing-function: var(--slide-up);
  animation-fill-mode: forwards;
  animation-direction: normal;
}
